<template>
  <div class="home">
    <span>Testemunhos</span>
    <div style="display: flex; width: 100%; height: 90%;">

    </div>
  </div>
</template>

<script>
import { computed  } from "vue";
import { mapActions } from "vuex";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "Terms",
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.getters.getUser);

    return { user, router };
  },
  methods: {
    ...mapActions("user", ["verifyWelcomeUser", "alterWelcomeUser", "verifyTermsUser", "alterTermsUser"]),

    async handleAccept() {
      // console.log("Termos aceitos!");
      await this.alterTermsUser(this.user.id);
      // console.log("Status de termos atualizado.");
      this.$router.push("/applications");
    },
  },
};
</script>
