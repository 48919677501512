<template>
  <div>
    <a-row>
      <a-col :span="isDesktop ? 18 : 24" style="padding-right: 10px">
        <a-col :span="24">
          <HomeFilter />
        </a-col>
        <a-col :span="24"> <MyProcesses /> </a-col
      ></a-col>
      <a-col :span="6" style="padding-left: 10px" v-if="isDesktop">
        <a-col :span="24">
          <NewProcessButton />
        </a-col>
        <a-col :span="24"><RecentProcess /> </a-col
      ></a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent, computed, createVNode } from "vue";
import { useRouter } from "vue-router";
import NewProcessButton from "@/components/NewProcessButton/NewProcessButton";
import RecentProcess from "@/components/RecentProcess/RecentProcess";
import MyProcesses from "@/components/MyProcesses/MyProcesses";
import HomeFilter from "@/components/HomeFilter/HomeFilter";
import { Modal } from "ant-design-vue";
import { useStore } from "vuex";
import { mapActions } from "vuex";
import { isDesktop } from "@/utils/isAgent";
export default defineComponent({
  components: {
    RecentProcess,
    MyProcesses,
    HomeFilter,
    NewProcessButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.getters.getUser);

    return { isDesktop, user, router };
  },
  methods: {
    ...mapActions("user", ["verifyWelcomeUser", "alterWelcomeUser", "verifyTermsUser", "alterTermsUser"]),

    async showTermsUser() {
      const response = await this.verifyTermsUser(this.user.id);
      if (response.status) {
        return;
      } else {
        this.$router.push("/applications/terms");
        return;
      }
    },

    async showWarningModal() {
      try {
        if (!this.user?.id) {
          return;
        }

        const response = await this.verifyWelcomeUser(this.user.id);
        console.log(response.status)
        if (response.status) {
          console.log("Usuário já recebeu boas-vindas.");
          this.showTermsUser();
          return;
        } else {
          Modal.success({
            title: "🎉 Bem vindo!",
            content: createVNode("div", {}, [
              createVNode("p", {}, `Seja bem-vindo à nossa aplicação, ${this.user?.name || "visitante"}! 🎊`),
              createVNode("iframe", {
                src: "https://player.vimeo.com/video/1018685407",
                width: "100%",
                height: "300px",
                frameborder: "0",
                allow: "autoplay; fullscreen; picture-in-picture",
                style: "margin-top: 20px; border-radius: 10px;",
                allowfullscreen: true,
              }),
            ]),
            width: 600,
            style: { top: "100px" },
            onOk: async () => {
              await this.alterWelcomeUser(this.user.id);
              this.showTermsUser();
            },
          });
        }
      } catch (error) {
        console.error("Erro ao verificar ou atualizar o status de boas-vindas:", error);
      }
    },
  },
  async mounted() {
    this.showWarningModal();
  }
});
</script>

<style module src="./style.scss" lang="scss"></style>
